"use strict";
import { isObject, isEqual, addToAssociativeCollection } from "./object-helper";
function getUrlByParams(url, params, defaultParams = {}) {
  const paramsPrefix = url.indexOf("?") === -1 ? "?" : "&";
  const paramsString = getQueryStringByParams(params, defaultParams);
  return `${url}` + (paramsString !== "" ? `${paramsPrefix}${paramsString}` : "");
}
function getUrlPrefixByParams(url, params) {
  const paramsPrefix = url.indexOf("?") === -1 ? "?" : "&";
  return `${url}` + (params !== "" ? `${paramsPrefix}${params}` : "");
}
function getQueryStringByParams(params, defaultParams = {}) {
  const searchableParams = getSearchableParams(params, defaultParams);
  return param(searchableParams);
}
function getSearchableParams(params, defaultParams = {}) {
  const result = {};
  Object.keys(params).forEach((p) => {
    if (!(p in defaultParams) || !isParametersValuesEqual(params[p], defaultParams[p])) {
      result[p] = params[p];
    }
  });
  return result;
}
function isParametersValuesEqual(parameter1, parameter2) {
  if (Array.isArray(parameter1) && !Array.isArray(parameter2) || !Array.isArray(parameter1) && Array.isArray(parameter2) || isObject(parameter1) && !isObject(parameter2) || !isObject(parameter1) && isObject(parameter2)) {
    throw new Error("one of the arguments is Array or Object and another is not");
  } else if (Array.isArray(parameter1) && Array.isArray(parameter2) || isObject(parameter1) && isObject(parameter2)) {
    return isEqual(parameter1, parameter2);
  }
  return parameter1 === parameter2;
}
function getSearchFromLocation(location) {
  return getParamsFromQueryString(location.search.substring(1));
}
function getParamsFromQueryString(queryString) {
  const str = queryString.replace(/\/$/, "") || "";
  const result = {};
  const parsedQueryParameters = [];
  str.split("&").forEach((keyValue) => {
    if (keyValue) {
      let value = keyValue.split("=");
      const key = decodeURIComponent(value[0]);
      value = value[1] ? decodeURIComponent(value[1]) : "";
      parsedQueryParameters.push([key, value]);
    }
  });
  parsedQueryParameters.forEach(([key, value]) => addToAssociativeCollection(result, key, value));
  return result;
}
function getParamFromLocation(paramName, location) {
  const params = getSearchFromLocation(location);
  if (paramName in params) {
    return params[paramName];
  }
  return null;
}
function getFullUrlWithAppliedParams(location, applyParams) {
  const params = getSearchFromLocation(location);
  function isMeaningfulValue(value) {
    return value !== void 0 && value !== null;
  }
  const finalParams = {};
  Object.keys(params).forEach((key) => {
    if (key in applyParams) {
      if (isMeaningfulValue(applyParams[key])) {
        finalParams[key] = applyParams[key];
      }
    } else {
      finalParams[key] = params[key];
    }
  });
  Object.keys(applyParams).forEach((key) => {
    if (!(key in params) && isMeaningfulValue(applyParams[key])) {
      finalParams[key] = applyParams[key];
    }
  });
  return `${location.origin}${getUrlByParams(location.pathname, finalParams)}`;
}
function param(params) {
  const s = [];
  const add = function(k, v) {
    v = typeof v === "function" ? v() : v;
    v = v === null || v === void 0 ? "" : v;
    s[s.length] = encodeURIComponent(k) + "=" + encodeURIComponent(v);
  };
  const buildParams = function(prefix, obj) {
    let i;
    let len;
    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            prefix + "[" + (typeof obj[i] === "object" && obj[i] ? i : i) + "]",
            obj[i]
          );
        }
      } else if (String(obj) === "[object Object]") {
        Object.keys(obj).forEach((_key) => {
          buildParams(prefix + "[" + _key + "]", obj[_key]);
        });
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      Object.keys(obj).forEach((_key) => {
        buildParams(_key, obj[_key]);
      });
    }
    return s;
  };
  return buildParams("", params).join("&");
}
function transformRequestOptions(params) {
  let options = "";
  Object.keys(params).forEach((key) => {
    if (typeof params[key] !== "object" && params[key]) {
      options += `${key}=${params[key]}&`;
    } else if (typeof params[key] === "object" && params[key] && params[key].length) {
      params[key].forEach((el) => {
        options += `${key}=${el}&`;
      });
    }
  });
  return options ? options.slice(0, -1) : options;
}
export {
  getUrlByParams,
  getUrlPrefixByParams,
  getQueryStringByParams,
  getSearchableParams,
  isParametersValuesEqual,
  getSearchFromLocation,
  getParamsFromQueryString,
  getParamFromLocation,
  getFullUrlWithAppliedParams,
  transformRequestOptions
};
