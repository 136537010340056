"use strict";
import isEqualLodash from "lodash/isEqual";
import filter from "lodash/filter";
import camelCase from "lodash/camelCase";
import matches from "lodash/matches";
const removeEmptyKeys = (obj, param) => Object.keys(obj).forEach((key) => {
  if (obj[key] === "" || obj[key] === null || obj[key] === void 0 || typeof obj[key] === "object" && Array.isArray(obj[key]) && !obj[key].length) {
    if (key !== param) {
      delete obj[key];
    }
  }
});
function clone(object) {
  return JSON.parse(JSON.stringify(object));
}
function isEqual(arg1, arg2) {
  return isEqualLodash(arg1, arg2);
}
function isObject(value) {
  return typeof value === "object";
}
function isPartialEqual(arr, obj) {
  return filter(arr, matches(obj));
}
function renameKey(obj, oldKey, newKey) {
  if (obj[oldKey] && oldKey !== newKey) {
    Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey));
    delete obj[oldKey];
    return true;
  }
  return false;
}
function convertObjectsKeysToCamelCase(obj) {
  Object.keys(obj).forEach((key) => {
    renameKey(obj, key, camelCase(key));
  });
}
function addToAssociativeCollection(collection, name, value) {
  const arrayRegexp = /(.+)\[(.*)\]/;
  const arrayParseResult = name.match(arrayRegexp);
  if (arrayParseResult !== null) {
    name = arrayParseResult[1];
    const key = arrayParseResult[2];
    const isObj = key !== "" && !/^\d+$/.test(key);
    if (!(name in collection)) {
      collection[name] = isObj ? {} : [];
    }
    if (isObj) {
      collection[name][key] = value;
    } else {
      collection[name].push(value);
    }
  } else {
    collection[name] = value;
  }
}
export {
  isEqual,
  isObject,
  addToAssociativeCollection,
  renameKey,
  clone,
  isPartialEqual,
  convertObjectsKeysToCamelCase,
  removeEmptyKeys
};
